import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

function PrivateRoute({ component: Component, ...restOfProps }) {
  const { isLoggedIn } = useSelector((state) => state.agent);
  const location = useLocation();
  const { title } = restOfProps;
  useEffect(() => {
    let pageName = '';
    if (location.pathname === '/') {
      pageName = 'Accounts';
    } else {
      const initialPaths = location.pathname.split('/');
      const n = initialPaths.length;

      pageName = _.capitalize(initialPaths[n - 1]);
    }

    if (title && title !== '') {
      pageName = _.startCase(_.toLower(title.replace(/_/g, ' ')));
    }

    pageName = _.startCase(_.toLower(pageName.replace(/_/g, ' ')));
    document.title = `${pageName} - FactorsAI`;
  }, [location]);
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  );
}

export default PrivateRoute;
