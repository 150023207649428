// useFileHandler.ts
import Papa from 'papaparse';

export const useFileHandler = () => {
  const readFile = (file: Blob | MediaSource): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        event.target?.result
          ? resolve(event.target.result as string)
          : reject(new Error('Failed to read file'));
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsText(file);
    });

  const parseFile = (text: string): string[] => {
    const { data } = Papa.parse(text);
    return data.map((row: any) => row[0]);
  };

  const getFileByteArray = (file: Blob): Promise<number[]> =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt?.target?.readyState === FileReader.DONE) {
          const array = new Uint8Array(evt.target.result as ArrayBuffer);
          resolve(Array.from(array));
        }
      };
    });

  return { readFile, parseFile, getFileByteArray };
};
