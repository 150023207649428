import React from 'react';
import { SVG, Text } from 'Components/factorsComponents';
import styles from './index.module.scss';

interface ProviderCardProps {
  isActive: boolean;
  icon: string;
  providerName: string;
  description?: string;
}

const ProviderCard = ({
  isActive,
  icon,
  providerName,
  description
}: ProviderCardProps) => (
  <div className={styles.providerCard}>
    <div className='flex items-center justify-center'>
      <SVG name={icon} size={44} color='purple' />
    </div>

    <div style={{ width: 178 }}>
      <Text
        type='title'
        level={7}
        weight='bold'
        extraClass='m-0'
        color='character-primary'
      >
        {providerName}
      </Text>
      <Text type='title' level={8} extraClass='m-0' color='character-secondary'>
        {description || `Using ${providerName} to identify accounts`}
      </Text>
    </div>

    <div className='flex items-center justify-center'>
      {isActive && <SVG name='CheckCircle' size={18} color='#1890FF' />}
    </div>
  </div>
);

export default ProviderCard;
