import { formatDurationIntoString } from 'Utils/dataFormatter';
import {
  ALL_USER_TYPE,
  ANY_USER_TYPE,
  DISPLAY_PROP,
  EVENT_QUERY_USER_TYPE,
  PREDEFINED_DATES,
  QUERY_TYPE_EVENT,
  REVERSE_USER_TYPES,
  TYPE_UNIQUE_USERS
} from 'Utils/constants';
import { GROUP_NAME_DOMAINS } from 'Components/GlobalFilter/FilterWrapper/utils';
import { operatorMap, reverseOperatorMap } from 'Utils/operatorMapping';
import { cloneDeep } from 'lodash';
import { generateRandomKey } from 'Utils/global';
import UiMessage from 'Components/UiMessage';
import {
  getEventsWithProperties,
  getStateQueryFromRequestQuery
} from '../../Views/CoreQuery/utils';
import MomentTz from '../MomentTz';
import { INITIAL_USER_PROFILES_FILTERS_STATE } from './AccountProfiles/accountProfiles.constants';

export const blockAccountFilterProperties = ['$account_activity_url'];

export const groups = {
  Timestamp: (item) =>
    MomentTz(item.timestamp * 1000).format('DD MMM YYYY, hh:mm:ss A'),
  Hourly: (item) =>
    `${MomentTz(item.timestamp * 1000)
      .startOf('hour')
      .format('hh A')} - ${MomentTz(item.timestamp * 1000)
      .add(1, 'hour')
      .startOf('hour')
      .format('hh A')} ${MomentTz(item.timestamp * 1000)
      .startOf('hour')
      .format('DD MMM YYYY')}`,
  Daily: (item) =>
    MomentTz(item.timestamp * 1000)
      .startOf('day')
      .format('DD MMM YYYY'),
  Weekly: (item) =>
    `${MomentTz(item.timestamp * 1000)
      .startOf('week')
      .format('DD MMM YYYY')} - ${MomentTz(item.timestamp * 1000)
      .endOf('week')
      .format('DD MMM YYYY')}`,
  Monthly: (item) =>
    MomentTz(item.timestamp * 1000)
      .startOf('month')
      .format('MMM YYYY'),
  Timeline: (item) =>
    MomentTz(item.timestamp * 1000)
      .startOf('day')
      .format('ddd, DD MMM YYYY')
};

const getEntityName = (caller, grpn) => {
  if (caller === 'account_profiles') {
    return grpn === 'user' ? 'user_group' : 'user_g';
  }
  return 'user_g';
};

export const formatFiltersForPayload = (
  filters = [],
  caller = 'user_profiles'
) => {
  const filterProps = [];
  const groupByRef = {};
  let count = 0;
  filters?.forEach((filter) => {
    let { ref } = filter;
    if (ref == undefined) {
      ref = count++;
    }
    if (!groupByRef[ref]) {
      groupByRef[ref] = [];
    }
    groupByRef[ref].push(filter);
  });
  for (const ref in groupByRef) {
    const filterGroup = groupByRef[ref];
    filterGroup.forEach((filter, i) => {
      const { values, props, operator } = filter;
      const vals = Array.isArray(values) ? filter.values : [filter.values];

      vals.forEach((val, index) => {
        let valueLop = '';
        if (index === 0 && i === 0) {
          valueLop = 'AND';
        } else if (
          operator === 'not equals' ||
          operator === 'does not contain'
        ) {
          valueLop = 'AND';
        } else {
          valueLop = 'OR';
        }
        filterProps.push({
          en: getEntityName(caller, props[0]),
          lop: valueLop,
          op: operatorMap[operator],
          grpn: props[0],
          pr: props[1],
          ty: props[2],
          va: val
        });
      });
    });
  }

  return filterProps;
};

export const getSegmentQuery = (queries, queryOptions, userType, caller) => {
  const query = {};
  query.grpa = queryOptions?.group_analysis;
  query.source = queryOptions?.source;
  query.caller = queryOptions?.caller;
  query.table_props = queryOptions?.table_props;
  query.cl = QUERY_TYPE_EVENT;
  query.ty = TYPE_UNIQUE_USERS;

  const period = {};
  if (queryOptions.date_range.from && queryOptions.date_range.to) {
    period.from = MomentTz(queryOptions.date_range.from).utc().unix();
    period.to = MomentTz(queryOptions.date_range.to).utc().unix();
  } else {
    period.from = MomentTz().startOf('week').utc().unix();
    period.to =
      MomentTz().format('dddd') !== 'Sunday'
        ? MomentTz().subtract(1, 'day').utc().unix()
        : MomentTz().utc().unix();
  }
  query.fr = period.from;
  query.to = period.to;

  query.ewp = getEventsWithProperties(queries);
  query.gup = formatFiltersForPayload(queryOptions?.globalFilters, caller);
  // Secondary filters should be encoded seperately
  // Because of reference issue
  query.gup = [
    ...query.gup,
    ...formatFiltersForPayload(queryOptions?.secondaryFilters, caller)
  ];
  query.ec = EVENT_QUERY_USER_TYPE[userType];
  query.tz = localStorage.getItem('project_timeZone') || 'Asia/Kolkata';
  return query;
};

export const IsDomainGroup = (source) =>
  source === GROUP_NAME_DOMAINS || source === 'All';

export const getFiltersRequestPayload = ({
  selectedFilters,
  tableProps,
  caller = 'account_profiles'
}) => {
  const { eventsList, eventProp, filters, account, secondaryFilters } =
    selectedFilters;

  const queryOptions = {
    group_analysis: account[1],
    source: caller === 'account_profiles' ? account[1] : 'All',
    caller,
    table_props: tableProps,
    globalFilters: [...filters],
    date_range: {},
    secondaryFilters: [...secondaryFilters]
  };

  return {
    query: getSegmentQuery(eventsList, queryOptions, eventProp, caller)
  };
};

export const formatReqPayload = (payload) => {
  let req = { query: { source: payload.source } };

  if (payload.segment) {
    const { query = {} } = payload.segment;
    req.query = {
      grpa: query.grpa || '',
      source: payload.source,
      ty: query.ty || '',
      ec: query.ec || '',
      ewp: query.ewp || [],
      gup: query.gup || [],
      table_props: query.table_props || []
    };
    req.segment_id = payload.segment.id;
  }

  if (payload?.search_filter?.length) {
    req = { ...req, search_filter: [...payload.search_filter] };
  }

  return req;
};

export const eventsFormattedForGranularity = (
  events,
  granularity,
  collapse = true
) => {
  const output = events.reduce((result, item) => {
    const byTimestamp = (result[groups[granularity](item)] =
      result[groups[granularity](item)] || {});
    const byUserId = (byTimestamp[
      item.username === 'new_user' ? item.username : item.user_id
    ] = byTimestamp[
      item.username === 'new_user' ? item.username : item.user_id
    ] || {
      events: [],
      collapsed: collapse
    });
    byUserId.events.push(item);
    return result;
  }, {});
  return output;
};

export const eventsGroupedByGranularity = (events, granularity) => {
  const groupedEvents = events.reduce((result, item) => {
    const timestampKey = groups[granularity](item);

    if (!result[timestampKey]) {
      result[timestampKey] = [];
    }

    result[timestampKey].push(item);

    return result;
  }, {});

  return groupedEvents;
};

export const toggleCellCollapse = (
  formattedData,
  timestamp,
  username,
  collapseState
) => {
  const data = { ...formattedData };
  data[timestamp][username].collapsed = collapseState;
  return data;
};

const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getHost = (urlstr) => {
  const uri = isValidHttpUrl(urlstr) ? new URL(urlstr).hostname : urlstr;
  return uri;
};

export const getUniqueItemsByKeyAndSearchTerm = (
  activities,
  searchTerm = ''
) => {
  if (!activities) {
    return [];
  }

  const isNotMilestone = (event) =>
    event && event.username !== 'milestone' && event.event_type !== 'milestone';

  const isUnique = (value, index, self) =>
    index === self.findIndex((t) => t && t.display_name === value.display_name);

  const matchesSearchTerm = (value) =>
    value &&
    value.display_name &&
    value.display_name.toLowerCase().includes(searchTerm.toLowerCase());

  return activities
    .filter(isNotMilestone)
    .filter(isUnique)
    .filter(matchesSearchTerm);
};

export const getPropType = (propsList, searchProp) => {
  let propType = 'categorical';
  propsList?.forEach((propArr) => {
    if (propArr[1] === searchProp) {
      propType = propArr[2];
    }
  });
  return propType;
};

export const timestampInSeconds = (timestamp) => {
  if (timestamp.toString().length > 10) {
    return Math.floor(timestamp / 1000);
  }
  return timestamp;
};

const formatNumerical = (prop, value) => {
  let localeParam;
  const isNumDuration = prop?.toLowerCase()?.includes('time');
  const isDurationMilliseconds = prop?.includes('durationmilliseconds');
  if (prop === '$6Signal_annual_revenue') {
    localeParam = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };
  }

  return isNumDuration
    ? formatDurationIntoString(parseFloat(value))
    : isDurationMilliseconds
      ? formatDurationIntoString(parseFloat(value / 1000))
      : parseInt(value).toLocaleString('en-US', localeParam);
};

const formatCategorical = (prop, value) => {
  const isTimestamp = prop?.includes('timestamp');
  const isCatDuration = prop?.endsWith('time');

  return isTimestamp
    ? MomentTz(value * 1000).format('DD MMM YYYY, hh:mm A zz')
    : isCatDuration
      ? formatDurationIntoString(parseFloat(value))
      : value;
};

const formatDatetime = (prop, value) => {
  const isDate = prop?.toLowerCase()?.includes('date');
  const valueInSeconds = timestampInSeconds(value);
  const dateFormat = isDate ? 'DD MMM YYYY' : 'DD MMM YYYY, hh:mm A zz';

  return MomentTz(valueInSeconds * 1000).format(dateFormat);
};

export const propValueFormat = (searchKey, value, type) => {
  if (!value) return '-';
  if (DISPLAY_PROP[value]) return DISPLAY_PROP[value];

  switch (type) {
    case 'datetime':
      return !Number.isNaN(parseInt(value))
        ? formatDatetime(searchKey, value)
        : value;

    case 'numerical':
      return !Number.isNaN(parseInt(value))
        ? formatNumerical(searchKey, value)
        : value;

    case 'categorical':
      return formatCategorical(searchKey, value);

    default:
      return value;
  }
};

export const getEventCategory = (event, eventNamesMap) => {
  let category = 'others';
  Object.entries(eventNamesMap).forEach(([groupName, events]) => {
    if (events.includes(event.event_name)) {
      category = groupName;
    }
  });
  if (event.display_name === 'Page View') {
    category = 'website';
  }
  return category;
};

export const getIconForCategory = (category) => {
  const source = category.toLowerCase();

  if (source.includes('hubspot')) {
    return 'hubspot';
  }
  if (source.includes('salesforce')) {
    return 'salesforce';
  }
  if (source.includes('leadsquared')) {
    return 'leadsquared';
  }
  if (source.includes('marketo')) {
    return 'marketo';
  }
  if (source === 'website') {
    return 'globe';
  }
  return 'mouseclick';
};

export const DefaultDateRangeForSegments = {
  from: MomentTz().subtract(28, 'days').startOf('day'),
  to: MomentTz().subtract(1, 'days').endOf('day'),
  frequency: MomentTz().format('dddd') === 'Monday' ? 'hour' : 'date',
  dateType:
    MomentTz().format('dddd') === 'Sunday'
      ? PREDEFINED_DATES.LAST_MONTH
      : PREDEFINED_DATES.THIS_MONTH
};

export const timestampToString = {
  Timestamp: (item) => MomentTz(item * 1000).format('DD MMM YYYY, hh:mm:ss A'),
  Hourly: (item) =>
    `${MomentTz(item * 1000)
      .startOf('hour')
      .format('hh A')} - ${MomentTz(item * 1000)
      .add(1, 'hour')
      .startOf('hour')
      .format('hh A')} ${MomentTz(item * 1000)
      .startOf('hour')
      .format('DD MMM YYYY')}`,
  Daily: (item) =>
    MomentTz(item * 1000)
      .startOf('day')
      .format('DD MMM YYYY'),

  Weekly: (item) =>
    `${MomentTz(item * 1000)
      .startOf('week')
      .format('DD MMM YYYY')} - ${MomentTz(item * 1000)
      .endOf('week')
      .format('DD MMM YYYY')}`,
  Monthly: (item) =>
    MomentTz(item * 1000)
      .startOf('month')
      .format('MMM YYYY')
};

export const sortStringColumn = (a = '', b = '') => {
  const compareA = typeof a === 'string' ? a.toLowerCase() : a;
  const compareB = typeof b === 'string' ? b.toLowerCase() : b;
  return compareA > compareB ? 1 : compareB > compareA ? -1 : 0;
};

export const sortNumericalColumn = (a = 0, b = 0) => a - b;

export const transformPayloadForWeightConfig = (payload) => {
  const {
    key: wid,
    label: event_name,
    weight,
    vr,
    filters,
    fname,
    category
  } = payload;
  const output = {
    wid,
    event_name,
    weight,
    is_deleted: false,
    rule: [],
    vr: vr === 0 ? 0 : 1,
    fname,
    category
  };

  if (filters?.length) {
    filters.forEach((filter) => {
      const { props, operator, values } = filter;
      const [property_type, key, value_type] = props;
      const rule = {
        key,
        value: value_type === 'categorical' ? values : [],
        operator: operatorMap[operator] || operator,
        property_type,
        value_type,
        lower_bound: value_type === 'numerical' ? parseInt(values) : 0
      };
      output.rule.push(rule);
    });
  } else {
    output.rule = null;
  }

  return output;
};

export const transformWeightConfigForQuery = (config) => {
  const {
    wid: key,
    event_name: label,
    weight,
    vr,
    rule,
    fname,
    category
  } = config;
  const output = {
    key,
    label,
    weight,
    filters: [],
    vr,
    fname,
    category
  };

  if (rule) {
    const rules = Array.isArray(rule) ? rule : [rule];

    rules.forEach((rule, index) => {
      const { value, value_type, lower_bound, property_type, key, operator } =
        rule;
      const ruleValues =
        Array.isArray(value) && value.length > 0
          ? value
          : value_type === 'categorical'
            ? [value]
            : value_type === 'numerical'
              ? lower_bound
              : value;
      const filter = {
        props: [property_type, key, value_type, property_type],
        operator: reverseOperatorMap[operator] || operator,
        values: ruleValues,
        ref: index
      };
      output.filters.push(filter);
    });
  }
  return output;
};

export const getSelectedFiltersFromQuery = ({
  query,
  groupsList,
  caller = 'account_profiles'
}) => {
  let eventProp =
    REVERSE_USER_TYPES[query.ec] != null
      ? REVERSE_USER_TYPES[query.ec]
      : caller === 'account_profiles'
        ? ALL_USER_TYPE
        : ANY_USER_TYPE;

  const grpa = Boolean(query.grpa) === true ? query.grpa : GROUP_NAME_DOMAINS;
  const filters = getStateQueryFromRequestQuery(query);

  // This is to support backward compatibility of the prev created Segments
  // We want all prev segments to have eventProp as "all" for default IF THERE is no eventsList
  if (caller === 'account_profiles' && filters && !filters.events?.length) {
    eventProp = ALL_USER_TYPE;
  }

  const result = {
    eventProp,
    filters:
      caller === 'account_profiles'
        ? filters.globalFilters.filter((elem) => elem.props[0] !== 'user')
        : filters.globalFilters,
    eventsList: filters.events,
    account:
      caller === 'account_profiles'
        ? groupsList.find((g) => g[1] === grpa)
        : INITIAL_USER_PROFILES_FILTERS_STATE.account,
    eventTimeline: '7',
    secondaryFilters:
      caller === 'account_profiles'
        ? filters.globalFilters.filter((elem) => elem.props[0] === 'user')
        : []
  };
  return result;
};

export const findKeyByValue = (data, targetValue) => {
  let foundKey = null;

  Object.entries(data).forEach(([key, values]) => {
    if (values.includes(targetValue)) {
      foundKey = key;
    }
  });

  return foundKey;
};

export const flattenObjects = (obj) =>
  Object.values(obj).reduce(
    (acc, nestedObj) => Object.assign(acc, nestedObj),
    {}
  );

export const AggregateFunctions = [
  { value: 'count', label: 'Count of event' },
  { value: 'sum', label: 'Sum of property' },
  { value: 'average', label: 'Average of property' },
  { value: 'distinct', label: 'Distinct of property' }
];

export const isEventPerformedOptions = [
  {
    value: true,
    label: 'did'
  },
  {
    value: false,
    label: 'did not do'
  }
];

export function removeInvalidFilterProperties(key, properties) {
  if (key === GROUP_NAME_DOMAINS && Array.isArray(properties)) {
    return properties?.filter(
      (e) => !blockAccountFilterProperties.includes(e[1])
    );
  }
  return properties;
}
export function mergeSegmentFilters(segmentPayload1, segmentPayload2) {
  if (
    segmentPayload1?.eventsList?.length >= 10 &&
    segmentPayload2?.eventsList?.length
  ) {
    UiMessage.info("Can't add more than 10 events");
    return segmentPayload1; // returning this will not lead to any state changes
  }
  const newFilters = [];
  const n = segmentPayload1?.filters?.length || 0;
  let lastIndexFilter = n > 0 ? segmentPayload1?.filters?.[n - 1]?.ref : 0;
  for (const filter of segmentPayload2.filters) {
    newFilters.push({ ...filter, ref: ++lastIndexFilter });
  }
  const iepToBlock = getIEPToBlock(segmentPayload1.eventsList);

  const eventCondition = segmentPayload1.eventProp;

  const response = {
    ...segmentPayload1,
    filters: [...cloneDeep(segmentPayload1.filters), ...cloneDeep(newFilters)],
    eventsList: [
      ...cloneDeep(segmentPayload1.eventsList),
      ...cloneDeep(
        segmentPayload2.eventsList?.map((e) => ({
          ...e,
          isEventPerformed:
            eventCondition === ANY_USER_TYPE ? !iepToBlock : e.isEventPerformed,
          key: generateRandomKey()
        }))
      )
    ],
    secondaryFilters: [
      ...cloneDeep(segmentPayload1.secondaryFilters),
      ...cloneDeep(segmentPayload2.secondaryFilters)
    ]
  };

  return response;
}

export function CheckSegmentFilters(selectedFilters) {
  const filters = selectedFilters?.filters;
  if (!filters) return true;

  return filters.every(({ values }) =>
    Array.isArray(values)
      ? values.length > 0 && values.every((v) => v != null)
      : values
  );
}

export function isThereDidNotConflict(eventsList) {
  const iep = eventsList.some((event) => event.isEventPerformed);
  const niep = eventsList.some((event) => !event.isEventPerformed);
  return iep && niep;
}

// if there are conflicts (did+didnot) condition block did-not
// else block the complement
export function getIEPToBlock(eventsList) {
  if (isThereDidNotConflict(eventsList)) {
    return false;
  }
  return !eventsList.some((event) => event.isEventPerformed);
}

// This filters Aggregate Properties based on the aggregate operator
// Sum & Avergae only work on numerical properties
export const validateAggregateProperties = (aggop, properties) =>
  properties?.filter((e) =>
    aggop === 'sum' || aggop === 'average' ? e[2] === 'numerical' : true
  );
