import { Collapse } from 'antd';
import React from 'react';
import { SVG } from 'Components/factorsComponents';
import style from './index.module.scss';

const { Panel } = Collapse;

interface CollapsibleContainerProps {
  keyName: string;
  BodyComponent: React.ReactNode;
  HeaderComponent: React.ReactNode;
  expandIcon?: (panelProps: any) => React.ReactNode;
  showBorder: boolean;
  openByDefault?: boolean;
  applyNoHeaderPadding?: boolean;
}

const DefaultExpandIcon = (panelProps: any) => {
  if (panelProps?.isActive) {
    return (
      <span className='anticon anticon-right ant-collapse-arrow'>
        <SVG name='CaretUp' size='24' />
      </span>
    );
  }
  return (
    <span className='anticon anticon-right ant-collapse-arrow'>
      <SVG name='CaretDown' size='24' />
    </span>
  );
};

const CollapsibleContainer = ({
  keyName,
  BodyComponent,
  HeaderComponent,
  expandIcon = DefaultExpandIcon,
  showBorder = true,
  openByDefault = false,
  applyNoHeaderPadding = false
}: CollapsibleContainerProps) => (
  <div className={`${applyNoHeaderPadding ? style.noPaddingHeader : ''}`}>
    <Collapse
      bordered={showBorder}
      defaultActiveKey={openByDefault ? [keyName] : undefined}
      expandIconPosition='right'
      className={`${style.collapse} ${showBorder ? style.border : ''}`}
      expandIcon={expandIcon}
    >
      <Panel key={keyName} header={HeaderComponent}>
        {BodyComponent}
      </Panel>
    </Collapse>
  </div>
);

export default CollapsibleContainer;
