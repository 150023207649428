import { useSelector } from 'react-redux';
import { FeatureConfigState } from 'Reducers/featureConfig/types';
import { getFeatureStatusInfo } from 'Reducers/featureConfig/utils';
import { FEATURES, FEATURE_AGENT_MAPPING } from '../constants/plans.constants';
import useAgentInfo from './useAgentInfo';

const useFeatureLock = (): {
  checkIsFeatureLocked: (
    name: (typeof FEATURES)[keyof typeof FEATURES]
  ) => boolean;
  isLoading: boolean;
} => {
  const featureConfig = useSelector(
    // @ts-ignore
    (state) => state.featureConfig
  ) as FeatureConfigState;
  const { email } = useAgentInfo();
  const checkIsFeatureLocked = (
    name: (typeof FEATURES)[keyof typeof FEATURES]
  ): boolean => {
    // returning false in case of loading state
    if (featureConfig.loading) {
      return false;
    }

    // for turning on a feature for specific users defined in FEATURE_AGENT_MAPPING
    if (FEATURE_AGENT_MAPPING[name]) {
      if (FEATURE_AGENT_MAPPING[name].includes(email)) {
        return false;
      }
    }

    const featureStatus = getFeatureStatusInfo(
      name,
      featureConfig.activeFeatures,
      featureConfig.addOns,
      featureConfig.billingAddons
    );
    return featureStatus.isFeatureLocked;
  };
  return {
    isLoading: featureConfig.loading,
    checkIsFeatureLocked
  };
};

export default useFeatureLock;
