import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchProjectSettings,
  udpateProjectSettings,
  enableHubspotIntegration
} from 'Reducers/global';
import {
  Row,
  Col,
  Modal,
  Input,
  Form,
  Button,
  notification,
  message,
  Skeleton
} from 'antd';
import { Text, FaErrorComp, FaErrorLog, SVG } from 'factorsComponents';
import { ErrorBoundary } from 'react-error-boundary';
import factorsai from 'factorsai';
import CollapsibleContainer from 'Components/GenericComponents/CollapsibleContainer';
import Header from 'Components/GenericComponents/CollapsibleContainer/CollasibleHeader';
import useFeatureLock from 'hooks/useFeatureLock';
import { FEATURES } from 'Constants/plans.constants';
import { AdminLock } from 'Routes/feature';
import logger from 'Utils/logger';
import useParagon from 'hooks/useParagon';
import { get, getHostUrl } from 'Utils/request';
import { getHost } from 'Components/Profile/utils';
import UiButton from 'Components/UiButton';
import UiDivider from 'Components/UiDivider';
import usePlanUpgrade from 'hooks/usePlanUpgrade';
import { sendSlackNotification } from '../../../../../utils/slack';
import { getCRMAllowedObjects, updateCRMObjects } from '../service';
import ConfigureCrmObjects from '../ConfigureCrmObjects';

const HubspotIntegration = ({
  fetchProjectSettings,
  udpateProjectSettings,
  activeProject,
  currentProjectSettings,
  currentAgent,
  enableHubspotIntegration,
  integrationCallback
}) => {
  const [errorInfo, seterrorInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [crmObjects, setCrmObjects] = useState([]);
  const [crmObjectsLoading, setCrmObjectsLoading] = useState(false);
  const { checkIsFeatureLocked } = useFeatureLock();
  const isHubspotFeatureLocked = checkIsFeatureLocked(FEATURES.FEATURE_HUBSPOT);

  const isHubspotEnabled = () =>
    currentProjectSettings &&
    currentProjectSettings?.int_hubspot &&
    currentProjectSettings?.int_hubspot_refresh_token != '';

  const { paragon, user, isLoaded, locked } = useParagon(activeProject?.id);
  const { handlePlanUpgradeClick } = usePlanUpgrade();
  const isParagonHubspotEnabled = useMemo(
    () => user?.integrations?.hubspot?.enabled,
    [activeProject?.id, user]
  );
  const onClickEnableHubspot = () => {
    setLoading(true);

    // Factors INTEGRATION tracking
    factorsai.track('INTEGRATION', {
      name: 'hubspot',
      activeProjectID: activeProject.id
    });

    enableHubspotIntegration(activeProject.id)
      .then((r) => {
        setLoading(false);
        sendSlackNotification(
          currentAgent.email,
          activeProject.name,
          'Hubspot'
        );
        if (r.status == 307) {
          window.location = r.data.redirect_url;
        }
      })
      .catch((err) => {
        setLoading(false);
        message.error(`${err?.data?.error}`);
      });
  };

  const onDisconnect = () => {
    Modal.confirm({
      title: 'Are you sure you want to disable this?',
      content:
        'You are about to disable this integration. Factors will stop bringing in data from this source.',
      okText: 'Disconnect',
      cancelText: 'Cancel',
      onOk: () => {
        setLoading(true);
        udpateProjectSettings(activeProject.id, {
          int_hubspot_api_key: '',
          int_hubspot: false
        })
          .then(() => {
            setLoading(false);
            setTimeout(() => {
              message.success('Hubspot integration disconnected!');
            }, 500);
            integrationCallback();
          })
          .catch((err) => {
            message.error(`${err?.data?.error}`);
            setLoading(false);
          });
      },
      onCancel: () => {}
    });
  };

  const isEnabled = isHubspotEnabled();

  const checkIsObjectDisabled = () => {
    if (!isHubspotFeatureLocked && AdminLock(currentAgent.email)) {
      return false;
    }
    return true;
  };

  const fetchHubspotObjects = async () => {
    try {
      setCrmObjectsLoading(true);
      const res = await getCRMAllowedObjects(activeProject.id, 'hubspot');
      if (res?.data) {
        const displayNames = res.data?.display_names || {};
        const allowedObjects = res.data?.allowed_objects || {};

        const objects = Object.keys(allowedObjects).map((obj) => ({
          label: displayNames[obj] || obj,
          value: obj,
          disabled: checkIsObjectDisabled(),
          allowed: allowedObjects?.[obj] || false
        }));
        setCrmObjects(objects);
      }
      setCrmObjectsLoading(false);
    } catch (error) {
      setCrmObjectsLoading(false);

      logger.error('Error in fetching hubspot ', error);
    }
  };

  const handleUpdateCrmObjects = async (data) => {
    try {
      await updateCRMObjects(activeProject.id, 'hubspot', data);
      await fetchHubspotObjects();
    } catch (err) {
      message.error(`${err?.data?.error}`);
    }
  };

  const renderHubspotDataPullBody = () => (
    <div className='mt-4 flex'>
      {isEnabled ? (
        <div className='flex flex-col gap-2 w-full'>
          {AdminLock(currentAgent?.email) && (
            <div className='w-full'>
              {crmObjectsLoading && <Skeleton />}
              {!crmObjectsLoading && (
                <ConfigureCrmObjects
                  objects={crmObjects}
                  handleApplyChanges={handleUpdateCrmObjects}
                  isObjectChangesAllowed={isHubspotFeatureLocked}
                />
              )}
            </div>
          )}

          <div>
            <Button loading={loading} onClick={() => onDisconnect()}>
              Disconnect
            </Button>
          </div>
        </div>
      ) : (
        <Button type='primary' loading={loading} onClick={onClickEnableHubspot}>
          Enable using Hubspot
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    fetchHubspotObjects();
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <FaErrorComp subtitle='Facing issues with Hubspot integrations' />
      }
      onError={FaErrorLog}
    >
      <div className='mt-6'>
        {/* Bring data from Hubspot */}
        <CollapsibleContainer
          openByDefault
          showBorder={false}
          keyName='Hubspot-data-pull'
          HeaderComponent={
            <Header
              title='Bring data from HubSpot'
              titleIcon='Hubspot_ads'
              description='Bring in your HubSpot objects like Contacts, Companies, Deals etc. and use them inside Factors.'
            />
          }
          BodyComponent={renderHubspotDataPullBody()}
          applyNoHeaderPadding
        />
      </div>
      <UiDivider />
      <div className='mt-6'>
        <CollapsibleContainer
          openByDefault
          showBorder={false}
          keyName='Hubspot-data-send'
          HeaderComponent={
            <Header
              title='Send data to HubSpot'
              titleIcon='Hubspot_ads'
              description='Configure workflows to add new contacts, companies in Hubspot directly from Factors.'
            />
          }
          BodyComponent={
            <div className='py-3'>
              <UiButton
                loading={!locked && !isLoaded}
                type={!isParagonHubspotEnabled && 'primary'}
                onClick={() => {
                  if (locked) {
                    handlePlanUpgradeClick();
                    return;
                  }
                  if (isParagonHubspotEnabled)
                    paragon.uninstallIntegration('hubspot');
                  else paragon.installIntegration('hubspot');
                }}
              >
                {locked
                  ? 'Upgrade plan to enable workflows'
                  : !isLoaded
                    ? 'Loading'
                    : isParagonHubspotEnabled
                      ? 'Disconnect'
                      : 'Connect'}
              </UiButton>
            </div>
          }
          applyNoHeaderPadding
        />
      </div>

      {/* Send data to Hubspot */}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  activeProject: state.global.active_project,
  currentProjectSettings: state.global.currentProjectSettings,
  currentAgent: state.agent.agent_details
});

export default connect(mapStateToProps, {
  fetchProjectSettings,
  udpateProjectSettings,
  enableHubspotIntegration
})(HubspotIntegration);
