import { Progress } from 'antd';
import React from 'react';

const ProgressBar = ({
  percentage,
  showInfo = false,
  trailColor = '#D9D9D9',
  strokeColor,
  smallSize = false
}: ProgressProps) => {
  let localStrokeColor = '';
  let localTrailColor = trailColor;
  if (!strokeColor) {
    if (percentage < 75) {
      localStrokeColor = '#1890FF';
      localTrailColor = '#E6F7FF';
    } else if (percentage < 100) {
      localStrokeColor = '#FFA940';
      localTrailColor = '#FFF7E6';
    } else {
      localStrokeColor = '#F5222D';
      localTrailColor = '#FFF1F0';
    }
  }

  return (
    <Progress
      percent={percentage}
      strokeColor={strokeColor || localStrokeColor}
      showInfo={showInfo}
      trailColor={localTrailColor}
      size={smallSize ? 'small' : 'default'}
    />
  );
};

type ProgressProps = {
  percentage: number;
  showInfo?: boolean;
  trailColor?: string;
  strokeColor?: string;
  smallSize?: boolean;
};

export default ProgressBar;
