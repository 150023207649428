import { MeteringTypes } from 'Reducers/featureConfig/types';

export const FEATURES = {
  // analyse section
  FEATURE_EVENTS: 'events',
  FEATURE_FUNNELS: 'funnels',
  FEATURE_KPIS: 'kpis',
  FEATURE_ATTRIBUTION: 'attribution',
  FEATURE_PROFILES: 'profiles',
  FEATURE_TEMPLATES: 'templates',

  FEATURE_HUBSPOT: 'hubspot',
  FEATURE_SALESFORCE: 'salesforce',
  FEATURE_LEADSQUARED: 'leadsqaured',
  FEATURE_GOOGLE_ADS: 'google_ads',
  FEATURE_FACEBOOK: 'facebook',
  FEATURE_LINKEDIN: 'linkedin',
  FEATURE_GOOGLE_ORGANIC: 'google_organic',
  FEATURE_BING_ADS: 'bing_ads',
  FEATURE_MARKETO: 'marketo',
  FEATURE_DRIFT: 'drift',
  FEATURE_APOLLO: 'apollo',
  FEATURE_ZOHO: 'zoho',
  FEATURE_GOOGLE_SHEETS: 'google_sheets',
  FEATURE_CLEARBIT: 'clearbit',
  FEATURE_SIX_SIGNAL: 'six_signal',
  FEATURE_DASHBOARD: 'dashboard',
  FEATURE_OFFLINE_TOUCHPOINTS: 'offline_touchpoints p',
  FEATURE_SAVED_QUERIES: 'saved_queries',
  FEATURE_EXPLAIN: 'explain_feature', // explain is a keyword in memsql.
  FEATURE_FILTERS: 'filters',
  FEATURE_SHAREABLE_URL: 'shareable_url',
  FEATURE_CUSTOM_METRICS: 'custom_metrics',
  FEATURE_SMART_PROPERTIES: 'smart_properties',
  FEATURE_CONTENT_GROUPS: 'content_groups',
  FEATURE_DISPLAY_NAMES: 'display_names',
  FEATURE_WEEKLY_INSIGHTS: 'weekly_insights',
  FEATURE_KPI_ALERTS: 'kpi_alerts',
  FEATURE_EVENT_BASED_ALERTS: 'event_based_alerts',
  FEATURE_WORKFLOWS: 'workflows',
  FEATURE_WORKFLOWS_HUBSPOT_COMPANY: 'workflows_hubspot_company',
  FEATURE_WORKFLOWS_HUBSPOT_CONTACT: 'workflows_hubspot_contact',
  FEATURE_WORKFLOWS_SALESFORCE_COMPANY: 'workflows_salesforce_company',
  FEATURE_WORKFLOWS_SALESFORCE_CONTACT: 'workflows_salesforce_contact',
  FEATURE_WORKFLOWS_LINKEDIN_CAPI: 'workflows_linkedin_capi',
  FEATURE_WORKFLOWS_LINKEDIN_AUDIENCE: 'workflows_linkedin_audience',
  FEATURE_WORKFLOWS_ZOHO_COMPANY: 'workflows_zoho_company',
  FEATURE_WORKFLOWS_ZOHO_CONTACT: 'workflows_zoho_contact',
  FEATURE_WORKFLOWS_HUBSPOT_DEALS: 'workflows_hubspot_deals',
  FEATURE_WORKFLOWS_APOLLO_SEQUENCE: 'workflows_apollo_sequence',
  FEATURE_WORKFLOWS_HEYREACH_LIST: 'workflows_heyreach_list',
  FEATURE_WORKFLOWS_HEYREACH_CAMPAIGN: 'workflows_heyreach_campaign',
  FEATURE_WORKFLOWS_GOOGLE_SHEETS_ACCOUNT: 'workflows_google_sheets_account',
  FEATURE_WORKFLOWS_SMARTLEAD_CAMPAIGN: 'workflows_smartlead_campaign',
  FEATURE_REPORT_SHARING: 'report_sharing',
  FEATURE_SLACK: 'slack',
  FEATURE_SEGMENT: 'segment',
  FEATURE_PATH_ANALYSIS: 'path_analysis',
  FEATURE_ARCHIVE_EVENTS: 'archive_events',
  FEATURE_BIG_QUERY_UPLOAD: 'big_query_upload',
  FEATURE_IMPORT_ADS: 'import_ads',
  FEATURE_LEADGEN: 'leadgen',
  FEATURE_TEAMS: 'teams',
  FEATURE_SIX_SIGNAL_REPORT: 'six_signal_report',
  FEATURE_ACCOUNT_SCORING: 'account_scoring',
  FEATURE_FACTORS_DEANONYMISATION: 'factors_deanonymisation',
  FEATURE_WEBHOOK: 'webhook',
  FEATURE_ACCOUNT_PROFILES: 'account_profiles',
  FEATURE_PEOPLE_PROFILES: 'people_profiles',
  FEATURE_CLICKABLE_ELEMENTS: 'clickable_elements',
  FEATURE_WEB_ANALYTICS_DASHBOARD: 'web_analytics_dashboard',
  FEATURE_G2: 'g2',
  FEATURE_RUDDERSTACK: 'rudderstack',
  FEATURE_SEGMENT_KPI: 'segment_kpi',
  FEATURE_LINKEDIN_FREQUENCY_CAPPING: 'linkedin_frequency_capping',
  FEATURE_DEMANDBASE: 'demandbase',
  FEATURE_HUBSPOT_BASIC: 'hubspot_basic',
  FEATURE_SALESFORCE_BASIC: 'salesforce_basic',
  FEATURE_SNITCHER: 'snitcher',
  FEATURE_REPORTS: 'reports',
  FEATURE_USERS: 'users',
  FEATURE_INTEREST_GROUP_SCORING: 'interest_group_scoring',
  FEATURE_REALTIME_SCORING: 'realtime_scoring',
  FEATURE_SEGMENT_EXPLAIN: 'segment_explain',
  FEATURE_PREDICTIVE_SCORE: 'predictive_score',
  FEATURE_SSO_GOOGLE: 'sso_google',
  FEATURE_SSO_SAML: 'sso_saml',
  FEATURE_ACCOUNT_SUMMARY: 'account_summary',
  CONF_CUSTOM_PROPERTIES: 'conf_custom_properties',
  CONF_CUSTOM_EVENTS: 'conf_custom_events',
  FEATURE_THIRD_PARTY_INTENT_UPLOAD: 'third_party_intent_upload'
};

export const PLANS = {
  PLAN_FREE: 'Free',
  PLAN_GROWTH: 'Growth',
  PLAN_BASIC: 'Basic',
  PLAN_PROFESSIONAL: 'Professional',
  PLAN_CUSTOM: 'Custom',
  PLAN_ENTERPRISE: 'Enterprise'
};

// adding for backward compatibility will be removed once we fully move to chargebee
export const PLANS_V0 = {
  PLAN_FREE: 'FREE',
  PLAN_STARTUP: 'STARTUP',
  PLAN_BASIC: 'BASIC',
  PLAN_PROFESSIONAL: 'PROFESSIONAL',
  PLAN_CUSTOM: 'CUSTOM'
};

export const PLANS_COFIG: PLANS_COFIG_INTERFACE = {
  [PLANS.PLAN_FREE]: {
    name: PLANS.PLAN_FREE,
    description: 'Reveal anonymous companies visiting your website',
    uniqueFeatures: [
      'Company Identification',
      'Customer Journey Timelines',
      'Starter GTM Dashboards',
      'Custom Reports & Segments',
      'Up to 2 Real-time Slack/MS Teams Alerts',
      'Integrations (Website, Slack, MS Teams)'
    ],
    isRecommendedPlan: false,
    planIcon: 'balloon',
    planIconColor: '#597EF7',
    mtuLimit: 5000,
    accountIdentifiedLimit: 200,
    seats: '2',
    icons: ['Globe', 'Slack', 'MSTeam']
  },

  [PLANS.PLAN_BASIC]: {
    name: PLANS.PLAN_BASIC,
    description:
      'Automate outbound to identified companies and generate pipeline',
    uniqueFeatures: [
      'Everything in Free +',
      'LinkedIn Intent Signals (Reveal companies viewing your LinkedIn ads)',
      'CSV Imports & Exports',
      'Advanced GTM Dashboards',
      'Advanced Website Analytics',
      'Custom Metrics & KPIs',
      'Global Exclusions Rules',
      'Customer Support (+support for 5x automations)',
      'Unlimited Real-time Slack/MS Teams Alerts',
      'Integrations (Google, LinkedIn, Facebook, Bing, Google Search Console, Webhooks)'
    ],
    isRecommendedPlan: false,
    planIcon: 'paper-plane-top',
    planIconColor: '#597EF7',
    mtuLimit: 10000,
    accountIdentifiedLimit: 3000,
    seats: '5',
    icons: [
      'Google_ads',
      'Linkedin_ads',
      'Facebook_ads',
      'Bing',
      'Google',
      'Webhook'
    ]
  },
  [PLANS.PLAN_GROWTH]: {
    name: PLANS.PLAN_GROWTH,
    description:
      'Track & prioritise your target accounts with custom scoring models',
    uniqueFeatures: [
      'Everything in Basic +',
      'Segment Insights',
      'ABM Analytics',
      'Account Scoring',
      'LinkedIn Attribution',
      'G2 Intent Signals (Reveal companies viewing your G2 pages)',
      'G2 Attribution ',
      'Dedicated Customer Success Manager (+support for unlimited automations)',
      'Integrations (HubSpot, SalesForce, Marketo, G2, Drift)',
      'Workflow Automations & Data Sync'
    ],
    isRecommendedPlan: true,
    planIcon: 'plane',
    planIconColor: '#597EF7',
    mtuLimit: 50000,
    accountIdentifiedLimit: 8000,
    seats: '',
    icons: ['Hubspot_ads', 'Salesforce_ads', 'Marketo', 'G2crowd', 'DriftLogo']
  },
  [PLANS.PLAN_CUSTOM]: {
    name: PLANS.PLAN_CUSTOM,
    description:
      'Bespoke plans for agencies or bigger teams looking to scale go-to-market',
    uniqueFeatures: [
      'Everything in Growth +',
      'AdPilot',
      'Multi-touch Attribution (Campaigns, Content Offline Events & more)',
      'Path analysis',
      'Buyer Journey Analysis with AI-Fuelled Explain',
      'Custom Metrics & KPIs',
      'White Glove Onboarding Support',
      'Integrations (RudderStack, Segment, Custom Integrations)'
    ],
    isRecommendedPlan: false,
    planIcon: 'rocket-launch',
    planIconColor: '#597EF7',
    mtuLimit: 100000,
    accountIdentifiedLimit: 10000,
    seats: '',
    icons: ['Rudderstack_ads', 'Segment_ads']
  },
  [PLANS.PLAN_ENTERPRISE]: {
    name: PLANS.PLAN_ENTERPRISE,
    description:
      'Bespoke plans for agencies or bigger teams looking to scale go-to-market',
    uniqueFeatures: [
      'Everything in Growth +',
      'AdPilot',
      'Multi-touch Attribution (Campaigns, Content Offline Events & more)',
      'Path analysis',
      'Buyer Journey Analysis with AI-Fuelled Explain',
      'Custom Metrics & KPIs',
      'White Glove Onboarding Support',
      'Integrations (RudderStack, Segment, Custom Integrations)'
    ],
    isRecommendedPlan: false,
    planIcon: 'rocket-launch',
    planIconColor: '#597EF7',
    mtuLimit: 100000,
    accountIdentifiedLimit: 10000,
    seats: '',
    icons: ['Rudderstack_ads', 'Segment_ads']
  }
};

export interface PLANS_COFIG_INTERFACE {
  [key: (typeof PLANS)[keyof typeof PLANS]]: PLAN_COFIG;
}

export interface PLAN_COFIG {
  name: (typeof PLANS)[keyof typeof PLANS];
  description: string;
  uniqueFeatures: string[];
  isRecommendedPlan: boolean;
  planIcon: string;
  planIconColor: string;
  mtuLimit: number;
  accountIdentifiedLimit: number;
  seats: string;
  icons?: string[];
}

export const ADDONS = {
  ADDITIONAL_ACCOUNTS_ADDON: 'Additional-500-Accounts-USD-Monthly',
  ADDONS_5_REALTIME_ALERTS: 'additional-5-real-time-alerts-USD-Monthly',
  ADDONS_5_SEGMENTS: 'additional-5-account-segments-USD-Monthly',
  ADDONS_1_USER: 'additional-1-seat-USD-Monthly',
  ADDITIONAL_25_REPORTS_ADDON_ID: 'additional-25-reports-USD-Monthly'
};

export interface ADDONS_CONFIG {
  id: (typeof ADDONS)[keyof typeof ADDONS];
  limit: number;
  title: string;
  description: string;
  sortOrder: number;
  perAddonName?: string;
  usageTitle: string;
  apiKeyIdentifier: MeteringTypes;
}

export interface ADDONS_CONFIG_INTERFACE {
  [key: (typeof ADDONS)[keyof typeof ADDONS]]: ADDONS_CONFIG;
}

export const ADDONS_CONFIG: ADDONS_CONFIG_INTERFACE = {
  [ADDONS.ADDITIONAL_ACCOUNTS_ADDON]: {
    id: ADDONS.ADDITIONAL_ACCOUNTS_ADDON,
    limit: 500,
    title: 'Accounts',
    description:
      'Get access to extra 500 account identification for one month.',
    sortOrder: 1,
    usageTitle: 'Accounts identified',
    apiKeyIdentifier: 'factors_deanonymisation'
  },
  [ADDONS.ADDONS_5_REALTIME_ALERTS]: {
    id: ADDONS.ADDONS_5_REALTIME_ALERTS,
    limit: 5,
    title: 'Realtime Alerts',
    description: 'Get access to extra 5 realtime alerts for one month.',
    sortOrder: 3,
    usageTitle: 'Alerts Quota',
    apiKeyIdentifier: 'event_based_alerts'
  },
  [ADDONS.ADDONS_5_SEGMENTS]: {
    id: ADDONS.ADDONS_5_SEGMENTS,
    limit: 5,
    title: 'Segments',
    description: 'Get access to 5 additional segments for one month',
    sortOrder: 2,
    usageTitle: 'Segment Quota',
    apiKeyIdentifier: 'segment'
  },
  [ADDONS.ADDONS_1_USER]: {
    id: ADDONS.ADDONS_1_USER,
    limit: 1,
    title: 'Members',
    description: 'Add an additional team member to the project',
    sortOrder: 5,
    perAddonName: 'Member',
    usageTitle: 'Members Quota',
    apiKeyIdentifier: 'users'
  },
  [ADDONS.ADDITIONAL_25_REPORTS_ADDON_ID]: {
    id: ADDONS.ADDITIONAL_25_REPORTS_ADDON_ID,
    limit: 25,
    title: 'Reports',
    description: 'Get access to 25 additional reports for one month',
    sortOrder: 4,
    usageTitle: 'Reports Quota',
    apiKeyIdentifier: 'reports'
  }
};

export const ADDITIONAL_ACCOUNTS_ADDON_ID =
  'Additional-500-Accounts-USD-Monthly';
export const ADDITIONAL_ACCOUNTS_ADDON_LIMIT = 500;

interface FEATURE_AGENT_MAPPING_INTERFACE {
  [key: (typeof FEATURES)[keyof typeof FEATURES]]: string[];
}

/*
  To be used in very rare case where you want a feature to be activated for  particular users for testing
  features initially 
*/
export const FEATURE_AGENT_MAPPING: FEATURE_AGENT_MAPPING_INTERFACE = {
  // [FEATURES.FEATURE_DEMANDBASE]: ['solutions@factors.ai', 'deepak@factors.ai'] // this feature will be activated regardless of the feature BE value
};
