import { Checkbox, Row, Col, Button, Divider, Modal } from 'antd';
import { SVG, Text } from 'Components/factorsComponents';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PathUrls } from 'Routes/pathUrls';
import logger from 'Utils/logger';

const { confirm } = Modal;
const ConfigureCrmObjects = ({
  objects,
  handleApplyChanges,
  isObjectChangesAllowed
}: ConfigureCrmObjectsProps) => {
  const [prevAllowedObjects, setPrevAllowedObjects] = useState<string[]>([]);

  const [localAllowedObjects, setLocalAllowedObjects] = useState<string[]>([]);
  const [showApplyButton, setShowApplyButton] = useState<boolean>(false);

  const handleCheckboxChange = (checkedValues: string[]) => {
    setLocalAllowedObjects(checkedValues);
  };

  const handleDiscardClick = () => {
    setLocalAllowedObjects(prevAllowedObjects);
  };

  const handleSaveClick = () => {
    confirm({
      title: 'Do you want to save these changes?',
      content: (
        <>
          Are you sure you want to save this changes? <br />
          Pulling of new objects will take at least 1 day.
        </>
      ),
      async onOk() {
        try {
          const data = {};
          localAllowedObjects.map((object) => {
            data[object] = true;
          });
          await handleApplyChanges(data);
          setPrevAllowedObjects(localAllowedObjects);
          setShowApplyButton(false);
        } catch (error) {
          logger.error('Error in saving crm object config', error);
        }
      },
      onCancel() {
        // Reset the switch value to the previous one
      }
    });
  };

  useEffect(() => {
    const allowedObjects = objects
      .filter((obj) => obj.allowed)
      .map((obj) => obj.value);
    setLocalAllowedObjects(allowedObjects);
    setPrevAllowedObjects(allowedObjects);
  }, [objects]);

  useEffect(() => {
    if (prevAllowedObjects.length !== localAllowedObjects.length) {
      setShowApplyButton(true);
    } else if (
      !isEqual(prevAllowedObjects.sort(), localAllowedObjects.sort())
    ) {
      setShowApplyButton(true);
    } else setShowApplyButton(false);
  }, [prevAllowedObjects, localAllowedObjects]);

  return (
    <div style={{ background: '#FAFAFA' }} className='p-4 rounded-md'>
      <Text type='title' weight='bold' level={7} extraClass='m-0'>
        Objects
      </Text>
      <Divider style={{ margin: '12px 0px' }} />
      <div>
        <Checkbox.Group
          value={localAllowedObjects}
          onChange={handleCheckboxChange}
        >
          <Row>
            {objects.map((object, i) => (
              <Col span='24' className={`${i !== 0 ? 'mt-2' : ''}`}>
                <Checkbox
                  value={object.value}
                  disabled={object.disabled}
                  //   checked={localAllowedObjects.includes(object.value)}
                >
                  {object.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
      {showApplyButton && !isObjectChangesAllowed && (
        <div className='flex items-center justify-end gap-2'>
          <Button onClick={handleDiscardClick}>Discard</Button>

          <Button type='primary' onClick={handleSaveClick}>
            Save
          </Button>
        </div>
      )}
      {isObjectChangesAllowed && (
        <div className='flex gap-1 mt-4'>
          <Text type='title' level={7} extraClass='m-0'>
            Access more objects by upgrading plan
          </Text>
          <Link
            className='inline-block ml-1'
            to={{
              pathname: PathUrls.SettingsPricing
            }}
          >
            <div className='flex items-center gap-2'>
              <SVG name='ArrowUpRightSquare' size={14} color='#1890ff' />

              <Text type='paragraph' mini weight='bold' color='brand-color-6'>
                Upgrade now
              </Text>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

interface ConfigureCrmObjectsProps {
  objects: {
    value: string;
    disabled: boolean;
    label: string;
    allowed: boolean;
  }[];
  handleApplyChanges: (objects: any) => void;
  isObjectChangesAllowed: boolean;
}

export default ConfigureCrmObjects;
