import { Tooltip, Typography } from 'antd';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import React from 'react';

const { Title, Paragraph } = Typography;

const textType = {
  title: 'title',
  paragraph: 'paragraph'
};

// Mapping old sizes to new Tailwind classes
const sizeMap = {
  1: 'text-4xl', // 2.5rem, line-height: 2.75rem
  2: 'text-3xl', // 2rem, line-height: 2.75rem
  3: 'text-2xl', // 1.5rem, line-height: 1.75rem
  4: 'text-xl', // 1.25rem, line-height: 1.5rem
  5: 'text-lg', // 1.125rem, line-height: 1.5rem
  6: 'text-base', // 1rem, line-height: 1.5rem
  7: 'text-sm', // 1rem, line-height: 1.5rem
  8: 'text-xs' // 0.875rem, line-height: 1.5rem
};

// Mapping weights to Tailwind classes
const weightMap = {
  thin: 'font-thin',
  regular: 'font-normal',
  normal: 'font-normal',
  medium: 'font-medium',
  bold: 'font-bold'
};

// Mapping line heights to Tailwind classes
const lineHeightMap = {
  normal: 'leading-normal',
  small: 'leading-none',
  medium: 'leading-snug',
  large: 'leading-loose'
};

// Mapping text alignment to Tailwind classes
const alignMap = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center'
};

// Mapping Colors to Tailwind classes
const colorMap = {
  red: '!text-red-5',
  orange: '!text-orange-4',
  green: '!text-green-5',
  grey: '!text-gray-7',
  'grey-2': '!text-gray-8',
  'grey-3': '!text-gray-6',
  'grey-8': '!text-gray-12',
  'grey-6': '!text-gray-12',
  'grey-7': '!text-gray-7',
  white: '!text-white',
  yellow: '!text-gold-4',
  blue: '!text-blue-5',
  'brand-6': '!text-purple-6',
  'brand-color-6': '!text-blue-6',
  'brand-color': '!text-blue-6',
  'neutral-gray-8': '!text-gray-8',
  black: '!text-black',
  disabled: '!text-gray-7',
  'character-primary': '!text-charcoal-65',
  'character-secondary': '!text-charcoal-45',
  'character-title': '!text-charcoal-85',
  'character-disabled-placeholder': '!text-charcoal-25'
};

class Text extends React.Component {
  getTailwindClasses() {
    const {
      size,
      level,
      weight,
      color,
      lineHeight,
      align,
      isUppercase,
      type,
      mini,
      extraClass
    } = this.props;

    const defaultFontSize =
      type === textType.paragraph ? (mini ? 7 : 6) : level || size;

    return classnames(
      'fai-text',
      // Size classes
      sizeMap[defaultFontSize],
      // Weight classes
      weightMap[weight || 'regular'],
      // Line height classes
      lineHeight && lineHeightMap[lineHeight],
      // Alignment classes
      align && alignMap[align],
      // Text transform
      isUppercase && 'uppercase',
      // Keep original color classes for backward compatibility
      color && colorMap[color],
      // Additional classes
      extraClass
    );
  }

  render() {
    const {
      type,
      level,
      children,
      truncate = false,
      toolTipTitle,
      charLimit = 30,
      ...otherProps
    } = this.props;

    // (Number.isInteger(isSizeDefined)
    // AntD throws error for level>4
    const isSizeDefined = level || this.props.size;

    // checks if text truncation and is child is string. ignores if its array.
    const isTextTruncatePossible = truncate && !isArray(children);

    const isOverFlow = children?.length > charLimit;

    let truncatedText = children;
    if (isTextTruncatePossible && isOverFlow) {
      truncatedText = `${children.slice(0, charLimit)}...`;
    }

    if (type === textType.title) {
      const sizeValue = isSizeDefined > 4 ? 4 : isSizeDefined;

      if ((isTextTruncatePossible && isOverFlow) || toolTipTitle) {
        return (
          <Tooltip placement='top' title={toolTipTitle || children}>
            <Title
              level={sizeValue}
              {...otherProps}
              className={this.getTailwindClasses()}
            >
              {truncatedText}
            </Title>
          </Tooltip>
        );
      }

      return (
        <Title
          level={sizeValue}
          {...otherProps}
          className={this.getTailwindClasses()}
        >
          {children}
        </Title>
      );
    }

    if (type === textType.paragraph) {
      return (
        <Paragraph {...otherProps} className={this.getTailwindClasses()}>
          {children}
        </Paragraph>
      );
    }

    console.error('Invalid type for Text (Factor-Components)');
    return null;
  }
}

export default Text;
