import CollapsibleContainer from 'Components/GenericComponents/CollapsibleContainer';
import Header from 'Components/GenericComponents/CollapsibleContainer/CollasibleHeader';
import UiButton from 'Components/UiButton';
import useParagon from 'hooks/useParagon';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

function Zoho() {
  const activeProjectId = useSelector(
    (state) => state.global.active_project?.id
  );
  const { user, paragon, isLoaded } = useParagon(activeProjectId);

  const isParagonZohoEnabled = useMemo(
    () => user?.integrations?.zohocrm?.enabled,
    [activeProjectId, user]
  );

  const ZohoIntegrationHandle = () => {
    if (isParagonZohoEnabled) paragon.uninstallIntegration('zohocrm');
    else paragon.installIntegration('zohocrm');
  };
  return (
    <div>
      <div className='mt-6'>
        <Header
          title='Send data to Zoho'
          titleIcon='zoho'
          description='Configure workflows to add new contacts and accounts in Zoho directly from Factors.'
        />
        <div className='py-3'>
          <UiButton
            type={!isParagonZohoEnabled && 'primary'}
            loading={!isLoaded}
            onClick={ZohoIntegrationHandle}
          >
            {!isLoaded
              ? 'Loading'
              : isParagonZohoEnabled
                ? 'Disconnect'
                : 'Connect'}
          </UiButton>
        </div>
      </div>
    </div>
  );
}

export default Zoho;
