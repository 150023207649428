import React from 'react';
import { SVG, Text } from 'Components/factorsComponents';
import ProgressBar from 'Components/GenericComponents/Progress';
import { useSelector } from 'react-redux';
import { FeatureConfigState } from 'Reducers/featureConfig/types';
import { useHistory, useLocation } from 'react-router-dom';
import { AdminLock } from 'Routes/feature';
import useAgentInfo from 'hooks/useAgentInfo';
import ProviderSelection from './ProviderSelection';

function ConnectedScreen() {
  const { meteringInfo } = useSelector(
    (state: any) => state.featureConfig
  ) as FeatureConfigState;
  const sixSignalInfo = meteringInfo?.factors_deanonymisation;

  const location = useLocation();
  const history = useHistory();
  const { email } = useAgentInfo();
  const sixSignalLimit = sixSignalInfo?.limit || 0;
  const sixSignalUsage = sixSignalInfo?.usage || 0;

  const handleCustomerSupportClick = () => {
    if (window?.Intercom) {
      window.Intercom(
        'showNewMessage',
        'Hi, I want to change my account identification provider. Can you share details about this?'
      );
    }
  };

  return (
    <div className='mt-5 flex flex-col  w-full'>
      <ProviderSelection />
      {!AdminLock(email) && (
        <div className='mt-2'>
          <Text
            type='title'
            level={7}
            extraClass='m-0 mt-2'
            color='character-secondary'
          >
            Please contact our{' '}
            <span
              onClick={handleCustomerSupportClick}
              className='cursor-pointer'
              style={{ color: '#1890FF' }}
            >
              customer support
            </span>{' '}
            team if you want to change your Deanonymization provider
          </Text>
        </div>
      )}

      <div className='mt-4'>
        <div className='flex justify-between items-center'>
          <div className='flex items-center justify-start gap-2'>
            <Text type='paragraph' mini>
              Default Monthly Quota
            </Text>
            <div
              className='flex items-center justify-start gap-1 cursor-pointer'
              onClick={() =>
                history.replace(
                  `${location.pathname}?activeTab=enrichmentRules`
                )
              }
            >
              <SVG name='ArrowUpRightSquare' size={14} color='#40A9FF' />

              <Text type='paragraph' mini color='brand-color'>
                Enrichment rules
              </Text>
            </div>
          </div>

          <Text type='paragraph' mini>
            {`${sixSignalUsage} / ${sixSignalLimit}`}
          </Text>
        </div>
        <ProgressBar percentage={(sixSignalUsage / sixSignalLimit) * 100} />
      </div>
    </div>
  );
}

export default ConnectedScreen;
