import AppModal from 'Components/AppModal';
import { Text } from 'Components/factorsComponents';
import logger from 'Utils/logger';
import { Button, Col, List, message, Row, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import csvTableImage from '../../assets/images/csvTable.svg';
import style from './index.module.scss';
import UiTabs from 'Components/UiTabs';
import UiIcon from 'Components/UiIcon';
import UiInput from 'Components/UiInput';
import { getUploadList } from 'Reducers/global';
import { connect, useSelector } from 'react-redux';
import { getHostUrl } from 'Utils/request';
import { CSVUploadModalProps, FileInfo } from './types';
import { useFileHandler } from './hooks/useFileHandler';
import { CONSTANTS } from './constants';
import UiSpin from 'Components/UiSpin';
let host = getHostUrl();
host = host[host.length - 1] === '/' ? host : `${host}/`;

function CSVUploadModal({
  uploadModalOpen,
  setUploadModalOpen,
  handleOkClick,
  uploadType = 'filters',
  getUploadList,
  handleOnClickSelect
}: CSVUploadModalProps) {
  const [uploadFileName, setUploadFileName] = useState('');
  const [uploadFileArray, setUploadFileArray] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCSVFile, setIsCSVFile] = useState(false);
  const [errorState, setErrorState] = useState<string>('');
  const [selected, setSelected] = useState({});
  const [searchVal, setSearchVal] = useState('');

  const [dataList, setDataList] = useState([]);
  const { active_project } = useSelector((state) => state.global);
  const { readFile, parseFile, getFileByteArray } = useFileHandler();

  useEffect(() => {
    if (uploadType === 'filters') {
      fetchUploadList();
    }
  }, [active_project, uploadType]);

  const fetchUploadList = async () => {
    try {
      const res = await getUploadList(active_project?.id);
      setDataList(res?.data);
    } catch (err) {
      logger.error(err);
    }
  };

  const handleSearchChange = useCallback((e) => {
    setSearchVal(e.target.value);
  }, []);

  const filteredData = dataList?.filter(
    (q) => q?.file_name.toLowerCase().indexOf(searchVal.toLowerCase()) > -1
  );

  const handleClickSelect = async () => {
    setLoading(true);
    try {
      if (selected?.file_uuid === undefined || selected?.file_uuid === '') {
        logger.error('error: please select file');
        return;
      }
      await handleOnClickSelect(selected);
      handleCancel();
    } catch (error) {
      logger.error(error);
    }
    setLoading(false);
  };

  const handleDownload = async (payload) => {
    try {
      if (!active_project?.id) {
        console.error('No active project ID found');
        return;
      }

      const response = await fetch(
        `${host}projects/${active_project.id}/downloaduploadedfile`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', payload?.file_name || 'download.csv');
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      message.error('Error downloading file:', error?.error);
    }
  };

  const validateFile = (fileInfo: FileInfo) => {
    const { type, size } = fileInfo.file;
    if (type !== 'text/csv') throw new Error('Only .csv files are allowed');
    if (
      uploadType === 'filters' &&
      size > CONSTANTS.FILE_LIMITS.FILTERS_MAX_SIZE
    ) {
      throw new Error('File is larger than 1 MB');
    }
  };

  const handleFileChange = async (fileInfo: FileInfo) => {
    if (!fileInfo?.file?.originFileObj) return;
    if (fileInfo?.file?.originFileObj) {
      try {
        validateFile(fileInfo);
        const text = await readFile(fileInfo.file.originFileObj);
        const dataArray = parseFile(text);

        if (uploadType === 'filters') {
          const fileByteArray = await getFileByteArray(
            fileInfo.file.originFileObj
          );
          setUploadFileArray(fileByteArray);
        } else {
          setUploadFileArray(dataArray);
        }

        setUploadFileName(fileInfo?.file?.name);

        if (dataArray.length > 50 && uploadType === 'urls')
          throw 'Can’t upload a sheet with more than 50 URLs';
        if (dataArray.length > 10000 && uploadType === 'filters')
          throw 'Can’t upload a sheet with more than 10,000 rows';

        setIsCSVFile(true);
        setErrorState('');
      } catch (error: any) {
        setIsCSVFile(false);
        setErrorState(error);
        logger.error(error);
      }
    }
  };

  const handleCancel = () => {
    setUploadModalOpen(false);
    setUploadFileName('');
    setUploadFileArray([]);
    setErrorState('');
    setIsCSVFile(false);
    setSearchVal('');
    setSelected({});
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      if (uploadFileArray.length === 0) {
        logger.error('error: empty file');
        return;
      }
      await handleOkClick(uploadFileArray, uploadFileName);
      handleCancel();
    } catch (error) {
      logger.error(error);
    }
    setLoading(false);
  };

  const listDataURLs = [
    <span>
      Add values in the first column only with{' '}
      <span className='font-bold'>no header</span>
    </span>,
    <span>
      You can upload a maximum of <span className='font-bold'>50 URLs</span>
    </span>,
    <span>
      Ensure that the file has a <span className='font-bold'>.csv</span>{' '}
      extension only
    </span>,
    <span>
      Don’t include <span className='font-bold'>https://</span> in the URL
    </span>
  ];

  const listDataFilters = [
    <span>
      Add values in the first column only with{' '}
      <span className='font-bold'>no header</span>
    </span>,
    <span>
      Ensure your CSV doesn't have {' > '}
      <span className='font-bold'>10K rows</span>
    </span>,
    <span>
      Ensure that the file has a <span className='font-bold'>.csv</span>{' '}
      extension only
    </span>,
    <span>
      Ensure that the file size isn’t {' > '}
      <span className='font-bold'>1MB</span>
    </span>
  ];

  const renderUploadTab = () => {
    return (
      <div>
        <div className='mt-4 mb-8'>
          <div className='flex justify-between'>
            <div>
              <Text extraClass='m-0' level={7} type='title' weight='bold'>
                Please note the following before uploading
              </Text>

              <List
                header={null}
                footer={null}
                split={false}
                dataSource={
                  uploadType === 'filters' ? listDataFilters : listDataURLs
                }
                renderItem={(item) => (
                  <List.Item>
                    <Text
                      type='title'
                      level={7}
                      color='grey'
                      extraClass='m-0 -mt-1 ml-2'
                    >
                      <span className={style.dot} />
                      {item}
                    </Text>
                  </List.Item>
                )}
              />
              <Text type='title' level={7} color='grey' extraClass='m-0'>
                In case of any doubts, here is a sample{' '}
                <a
                  href={
                    uploadType === 'filters'
                      ? CONSTANTS.SAMPLE_CSV_FILTERS
                      : CONSTANTS.SAMPLE_CSV_URLS
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  file
                </a>
              </Text>
            </div>
            <div>
              <div>
                <img src={csvTableImage} alt='csv table' />
                <Text type='title' level={7} color='grey' extraClass='m-0 mt-2'>
                  We read values in the first column starting from A1
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div className='border rounded mt-2 flex justify-center '>
          <Upload
            showUploadList={false}
            onChange={handleFileChange}
            accept='.csv'
            maxCount={1}
            className='text-center'
          >
            <div className='p-8'>
              {uploadFileName ? (
                <Button className='inline'>{uploadFileName}</Button>
              ) : (
                <>
                  <Button
                    icon={
                      <UiIcon
                        iconName='arrow-up-from-line'
                        name='arrow-up-from-line'
                        size='1x'
                      />
                    }
                  >
                    Upload CSV
                  </Button>
                  {uploadType === 'filters' && (
                    <Text
                      type='title'
                      level={7}
                      color='grey'
                      extraClass='m-0 mt-2'
                    >
                      Upload a new CSV or select one from your library.
                    </Text>
                  )}
                </>
              )}
            </div>
          </Upload>
        </div>
        {errorState && (
          <div>
            <Text type='title' level={7} color='red' extraClass='m-0'>
              {errorState}
            </Text>
          </div>
        )}
        <Row className='mt-4'>
          <Col span={24}>
            <div className='flex justify-end'>
              <Button
                size='middle'
                className='mr-2'
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                size='middle'
                className='ml-2'
                type='primary'
                onClick={() => handleOk()}
                disabled={!uploadFileName || !isCSVFile}
                loading={loading}
              >
                Upload and Continue
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderListTab = () => {
    return (
      <div className='m-0'>
        <Text extraClass='m-0' color='grey' mini type='paragraph'>
          Select a CSV from your previous uploads. Hover on an item if you’d
          like to download the file.
        </Text>
        <div className='border rounded mt-3'>
          <div>
            <UiInput
              onChange={handleSearchChange}
              placeholder='Search list'
              size='middle'
              className={`fa-input m-3`}
              style={{ padding: '8px 11px', width: '97%' }}
              prefix={
                <UiIcon name='magnifying-glass' color='grey' type='regular' />
              }
              suffix={null}
              value={searchVal}
            />
          </div>
          {dataList?.length > 0 ? (
            <div className='overflow-y-auto' style={{ height: '340px' }}>
              {filteredData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className='relative group/item hover:bg-gray-50'
                  >
                    <div
                      className={` flex items-center gap-2 p-1 m-2 mt-0 rounded-lg ${
                        selected?.file_uuid !== item.file_uuid
                          ? 'hover:bg-gray-3'
                          : 'hover:bg-brand-1'
                      } transition-colors duration-200 ${
                        selected?.file_uuid === item.file_uuid
                          ? 'bg-brand-1'
                          : ''
                      }`}
                    >
                      <button
                        onClick={() => {
                          setSelected(item);
                        }}
                        type='button'
                        className='flex items-center gap-2 flex-grow'
                      >
                        <div className='flex flex-col text-left'>
                          <Text
                            type='title'
                            level={6}
                            weight='normal'
                            extraClass={`m-0 ml-2 ${
                              selected?.file_uuid === item.file_uuid
                                ? '!text-brand-5'
                                : ''
                            }`}
                          >
                            {item?.file_name.split('.')?.[0]}
                          </Text>
                        </div>
                      </button>
                      <div>
                        <button
                          type='button'
                          onClick={() => handleDownload(item)}
                          className='flex items-center justify-center w-8 h-8 rounded-md transition-colors duration-200 flex-shrink-0 hover:bg-gray-100'
                        >
                          <UiIcon
                            name='download'
                            type='regular'
                            size='md'
                            className={`p-2 transition-opacity duration-200 opacity-0 group-hover/item:opacity-100`}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='flex justify-center'>
              <UiSpin size='large' spinning />
            </div>
          )}
        </div>
        <Row className='mt-4'>
          <Col span={24}>
            <div className='flex justify-end'>
              <Button
                size='middle'
                className='mr-2'
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                size='middle'
                className='ml-2'
                type='primary'
                onClick={() => handleClickSelect()}
                disabled={
                  selected?.file_uuid === undefined ||
                  selected?.file_uuid === ''
                }
                loading={loading}
              >
                Select and Continue
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <AppModal
      visible={uploadModalOpen}
      width={780}
      closable
      title={null}
      footer={null}
      className={style.container}
      onCancel={() => handleCancel()}
    >
      <div>
        <div>
          <Text type='title' level={4} weight='bold' extraClass='m-0'>
            {uploadType === 'filters' ? 'Manage CSV' : 'Upload CSV'}
          </Text>
          <Text extraClass='m-0' color='grey' mini type='paragraph'>
            {uploadType === 'filters'
              ? `Upload a new CSV file, or select a CSV file from your previous uploads`
              : `Import a CSV with list of page URLs to include or exclude`}
          </Text>
        </div>
        <UiTabs defaultActiveKey='1' tabPosition='top' size='small'>
          <UiTabs.TabPane
            tab={
              <div className='flex space-between'>
                <UiIcon
                  className='mt-4'
                  iconName='arrow-up-from-line'
                  name='arrow-up-from-line'
                  size='1x'
                />
                <span className='-ml-1 mt-3'>Upload New File</span>
              </div>
            }
            key='1'
          >
            {uploadType === 'filters' && (
              <Text extraClass='m-0' color='grey' mini type='paragraph'>
                Import a list of accounts, domains, and page URLs to filter
                with.
              </Text>
            )}
            {renderUploadTab()}
          </UiTabs.TabPane>
          {uploadType === 'filters' && (
            <UiTabs.TabPane
              tab={
                <div className='flex space-between'>
                  <UiIcon
                    className='mt-4'
                    iconName='list-ul'
                    name='list-ul'
                    size='1x'
                  />
                  <span className='-ml-1 mt-3'>Choose from Library</span>
                </div>
              }
              key='2'
            >
              {renderListTab()}
            </UiTabs.TabPane>
          )}
        </UiTabs>
      </div>
    </AppModal>
  );
}

export default connect(null, {
  getUploadList
})(CSVUploadModal);
