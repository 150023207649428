// src/components/UiInput.js
import { Input } from 'antd';
import React from 'react';

// Input elements usually need many dom manipulations, like focus, value update etc.
const UiInput = React.forwardRef(({ width, ...props }, ref) => {
  // Map width options to actual CSS styles
  const widthStyles = {
    full: { width: '100%' },
    compact: { width: '35%', minWidth: '300px' }
    // No entry for undefined/null/other values will apply no width styling
  };

  // Get width style if a valid option was provided
  const widthStyle = width && widthStyles[width] ? widthStyles[width] : {};

  // Merge any existing style props with our width styles
  const mergedStyle = {
    ...props.style,
    ...widthStyle
  };

  return <Input {...props} style={mergedStyle} ref={ref} />;
});

// Maintain original Input subcomponents
UiInput.Group = Input.Group;
UiInput.Search = Input.Search;
UiInput.TextArea = Input.TextArea;
UiInput.Password = Input.Password;

export default UiInput;
