import { PlansDetailStateInterface } from 'Reducers/plansConfig/types';

export const showUpgradeNudge = (
  utilisedAmount: number,
  totalAmount: number,
  currentProjectSettings: any
) => {
  if (
    (currentProjectSettings?.int_client_six_signal_key &&
      currentProjectSettings?.client6_signal_key) ||
    (currentProjectSettings?.int_clear_bit &&
      currentProjectSettings?.clearbit_key)
  )
    return false;
  const percentage = Number(((utilisedAmount / totalAmount) * 100).toFixed(2));
  return percentage >= 75;
};

export const PRICING_PAGE_TABS = {
  BILLING: 'billing',
  ENRICHMENT_RULES: 'enrichment_rules',
  UPGRADE: 'upgrade',
  INVOICES: 'invoices'
};

export const showV2PricingVersion = (activeProject: any) => {
  // return true;

  if (activeProject?.enable_billing) return true;
  return false;
};

export const PRICING_HELP_LINK =
  'https://factors.schedulehero.io/campaign/global-round-robin-ssos';

export const getExternalNameFromPlansConfig = (
  id: string,
  plans: PlansDetailStateInterface[]
) => {
  const plan = plans?.find((p) => {
    const planTerm = p?.terms.find((term) => term.id === id);
    if (planTerm) return true;
    return false;
  });
  if (plan) return plan.name;
  return '';
};
