import logger from 'Utils/logger';
import { getHostUrl, get, post } from 'Utils/request';
import { CrmType } from './types';

const host = getHostUrl();

export const getIntegrationStatus = async (projectId: string) => {
  try {
    if (!projectId) {
      throw new Error('Invalid parameters passed');
    }
    const url = `${host}projects/${projectId}/integrations_status`;
    return get(null, url);
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export const getCRMAllowedObjects = async (
  projectId: string,
  type: CrmType
) => {
  try {
    if (!projectId || !type) {
      throw new Error('Invalid parameters passed');
    }
    const url = `${host}projects/${projectId}/v1/crm/allowed_objects/${type}`;
    return get(null, url);
  } catch (error) {
    logger.error(error);
    return null;
  }
};

export const updateCRMObjects = async (
  projectId: string,
  type: CrmType,
  data: { [key: string]: true }
) => {
  try {
    if (!projectId || !type) {
      throw new Error('Invalid parameters passed');
    }
    const url = `${host}projects/${projectId}/v1/crm/allowed_objects/${type}`;
    return post(null, url, data);
  } catch (error) {
    logger.error(error);
    return null;
  }
};
