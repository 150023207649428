import CollapsibleContainer from 'Components/GenericComponents/CollapsibleContainer';
import Header from 'Components/GenericComponents/CollapsibleContainer/CollasibleHeader';
import React from 'react';
import { Divider } from 'antd';
import SixSignalFactors from '../SixSignalFactors';
import ExternalProvider from './ExternalProvider';

interface IndentificationProviderProps {
  kbLink: string;
}

const IndentificationProvider = ({ kbLink }: IndentificationProviderProps) => (
  <div className={` flex flex-col`}>
    <CollapsibleContainer
      showBorder={false}
      keyName='factors'
      openByDefault
      HeaderComponent={
        <Header
          title='Factors Deanonymization'
          description='Use Factors Deanonymization to identify accounts visiting your website. Monthly quota is calculated based on your plan.'
        />
      }
      BodyComponent={<SixSignalFactors kbLink={kbLink} />}
      applyNoHeaderPadding
    />
    <Divider />
    <CollapsibleContainer
      showBorder={false}
      keyName='third-party'
      HeaderComponent={
        <Header
          title='Third-Party Integrations'
          description='Use your existing API key to identify accounts visiting your website. Your usage is metered by your API provider directly.'
        />
      }
      BodyComponent={<ExternalProvider />}
      applyNoHeaderPadding
    />
  </div>
);

export default IndentificationProvider;
