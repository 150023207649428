import { useCallback, useEffect, useState } from 'react';
import { paragon, SDK_EVENT } from '@useparagon/connect/dist/src/index';
import { message } from 'antd';
import { get, getHostUrl } from 'Utils/request';
import logger from 'Utils/logger';
import useFeatureLock from './useFeatureLock';
import { FEATURES } from 'Constants/plans.constants';


export default function useParagon(projectID) {
  const [token, setToken] = useState('');
  const [user, setUser] = useState(paragon.getUser());
  const [error, setError] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const { checkIsFeatureLocked, isLoading } = useFeatureLock();
  const isWorkflowLocked = checkIsFeatureLocked(FEATURES.FEATURE_WORKFLOWS);

  const updateUser = useCallback(() => {
    if (token) {
      const authedUser = paragon.getUser();
      if (authedUser.authenticated) {
        setUser({ ...authedUser });
      }
    }
  }, [token]);

  // Listen for account state changes
  useEffect(() => {
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, updateUser);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, updateUser);
    };
  }, [token]);

  useEffect(() => {
    if (!error && token) {
      paragon
        .authenticate('60ef58ab-2e11-4f44-a388-b81aafca37ad', token)
        .then(() => {
          const authedUser = paragon.getUser();
          if (authedUser.authenticated) {
            setUser(authedUser);
          }
          setIsLoaded(true);
        })
        .catch((reason) => {
          setError(reason);
          setIsLoaded(true);
          message.error('Token Authentication Failed');
        });
    }
  }, [error, token]);

  const fetchToken = async () => {
    get(null, `${getHostUrl()}projects/${projectID}/paragon/auth`)
      .then((res) => {
        if (!res?.data) {
          logger.error('JWT Token not found');
          return;
        }

        setToken(res?.data);
      })
      .catch((err) => {
        logger.error(err);
        message.error('Token not found!');
      });
  };
  useEffect(() => {
    // No Need to Fetch Token and Trigger following sequences
    // if workflow is not enabled.
    if (!isLoading && !isWorkflowLocked) {
      fetchToken();
    }
  }, [isLoading]);

  return {
    paragon,
    user,
    error,
    updateUser,
    isLoaded,
    locked: isWorkflowLocked
  };
}
