import { Button } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './index.module.scss';

const UiButton = ({
  children,
  icon,
  iconPosition = '',
  size = 'middle',
  variant = '',
  type,
  className,
  ...props
}) => {
  // Handling multiple variant classes based on variant
  const variantClass = {
    fullWidth: styles.full_width
  };

  return (
    <Button
      {...props}
      size={size}
      type={type}
      className={cx(className, variant && variantClass[variant])}
    >
      {iconPosition === '' || iconPosition === 'start' ? icon : null}
      {children}
      {iconPosition === 'end' && icon}
    </Button>
  );
};

UiButton.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(['start', 'end']),
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  variant: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string
};

export default UiButton;
