import { Checkbox } from 'antd';
import React from 'react';

const UiCheckbox = ({ children, ...props }) => (
  <Checkbox {...props}>{children}</Checkbox>
);

// Assign Checkbox.Group directly to UiCheckbox.Group
UiCheckbox.Group = Checkbox.Group;

export default UiCheckbox;
