import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchProjectSettings,
  udpateProjectSettings,
  enableSalesforceIntegration,
  fetchSalesforceRedirectURL
} from 'Reducers/global';
import { Input, Button, message, Modal, Skeleton } from 'antd';
import { Text, FaErrorComp, FaErrorLog } from 'factorsComponents';
import { ErrorBoundary } from 'react-error-boundary';
import factorsai from 'factorsai';
import CollapsibleContainer from 'Components/GenericComponents/CollapsibleContainer';
import Header from 'Components/GenericComponents/CollapsibleContainer/CollasibleHeader';
import useFeatureLock from 'hooks/useFeatureLock';
import { FEATURES } from 'Constants/plans.constants';
import logger from 'Utils/logger';
import { AdminLock } from 'Routes/feature';
import useParagon from 'hooks/useParagon';
import UiDivider from 'Components/UiDivider';
import UiButton from 'Components/UiButton';
import usePlanUpgrade from 'hooks/usePlanUpgrade';
import { getCRMAllowedObjects, updateCRMObjects } from '../service';
import { sendSlackNotification } from '../../../../../utils/slack';
import ConfigureCrmObjects from '../ConfigureCrmObjects';

const SalesForceIntegration = ({
  fetchProjectSettings,
  udpateProjectSettings,
  activeProject,
  currentProjectSettings,
  enableSalesforceIntegration,
  fetchSalesforceRedirectURL,
  currentAgent,
  integrationCallback
}) => {
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [crmObjects, setCrmObjects] = useState([]);
  const [crmObjectsLoading, setCrmObjectsLoading] = useState(false);
  const { checkIsFeatureLocked } = useFeatureLock();
  const isSalesforceFeatureLocked = checkIsFeatureLocked(
    FEATURES.FEATURE_SALESFORCE
  );

  const { paragon, user, isLoaded, locked } = useParagon(activeProject?.id);
  const { handlePlanUpgradeClick } = usePlanUpgrade();
  const isParagonSalesforceEnabled = useMemo(
    () => user?.integrations?.salesforce?.enabled,
    [activeProject?.id, user]
  );

  const isSalesforceEnabled = () =>
    currentProjectSettings &&
    currentProjectSettings.int_salesforce_enabled_agent_uuid &&
    currentProjectSettings.int_salesforce_enabled_agent_uuid != '';

  const handleRedirectToURL = () => {
    fetchSalesforceRedirectURL(activeProject.id.toString()).then((r) => {
      if (r.status == 307) {
        window.location = r.data.redirectURL;
      }
    });
  };

  const onClickEnableSalesforce = () => {
    // Factors INTEGRATION tracking
    factorsai.track('INTEGRATION', {
      name: 'salesforce',
      activeProjectID: activeProject.id
    });

    enableSalesforceIntegration(activeProject.id.toString()).then((r) => {
      sendSlackNotification(
        currentAgent.email,
        activeProject.name,
        'Salesforce'
      );
      if (r.status == 304) {
        handleRedirectToURL();
      }
    });
  };

  const onDisconnect = () => {
    Modal.confirm({
      title: 'Are you sure you want to disable this?',
      content:
        'You are about to disable this integration. Factors will stop bringing in data from this source.',
      okText: 'Disconnect',
      cancelText: 'Cancel',
      onOk: () => {
        setLoading(true);
        udpateProjectSettings(activeProject.id, {
          int_salesforce_enabled_agent_uuid: ''
        })
          .then(() => {
            setLoading(false);
            setShowForm(false);
            setTimeout(() => {
              message.success('Salesforce integration disconnected!');
            }, 500);
            integrationCallback();
          })
          .catch((err) => {
            message.error(`${err?.data?.error}`);
            setShowForm(false);
            setLoading(false);
          });
      },
      onCancel: () => {}
    });
  };

  const isEnabled = isSalesforceEnabled();

  const checkIsObjectDisabled = () => {
    if (!isSalesforceFeatureLocked && AdminLock(currentAgent.email)) {
      return false;
    }
    return true;
  };

  const fetchSalesforceObjects = async () => {
    try {
      setCrmObjectsLoading(true);
      const res = await getCRMAllowedObjects(activeProject.id, 'salesforce');
      if (res?.data) {
        const displayNames = res.data?.display_names || {};
        const allowedObjects = res.data?.allowed_objects || {};

        const objects = Object.keys(allowedObjects).map((obj) => ({
          label: displayNames[obj] || obj,
          value: obj,
          disabled: checkIsObjectDisabled(),
          allowed: allowedObjects?.[obj] || false
        }));
        setCrmObjects(objects);
      }
      setCrmObjectsLoading(false);
    } catch (error) {
      setCrmObjectsLoading(false);

      logger.error('Error in fetching hubspot ', error);
    }
  };

  const handleUpdateCrmObjects = async (data) => {
    try {
      await updateCRMObjects(activeProject.id, 'salesforce', data);
      await fetchSalesforceObjects();
    } catch (err) {
      message.error(`${err?.data?.error}`);
    }
  };

  const renderSalesforceDataPullBody = () => (
    <div className='mt-4 flex w-full'>
      {isEnabled && (
        <div className='flex flex-col gap-2 w-full'>
          {AdminLock(currentAgent.email) && (
            <div className='w-full'>
              {crmObjectsLoading && <Skeleton />}
              {!crmObjectsLoading && (
                <ConfigureCrmObjects
                  objects={crmObjects}
                  handleApplyChanges={handleUpdateCrmObjects}
                  isObjectChangesAllowed={isSalesforceFeatureLocked}
                />
              )}
            </div>
          )}

          <div>
            <Button loading={loading} onClick={() => onDisconnect()}>
              Disconnect
            </Button>
          </div>
        </div>
      )}
      {!isEnabled && (
        <Button
          type='primary'
          loading={loading}
          onClick={onClickEnableSalesforce}
        >
          Connect Salesforce
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    fetchSalesforceObjects();
  }, []);

  return (
    <ErrorBoundary
      fallback={
        <FaErrorComp subtitle='Facing issues with Salesforce integrations' />
      }
      onError={FaErrorLog}
    >
      <div className='mt-6'>
        {/* Bring data from Salesforce */}
        <CollapsibleContainer
          openByDefault
          showBorder={false}
          keyName='Salesforce-data-pull'
          HeaderComponent={
            <Header
              title='Bring data from Salesforce'
              titleIcon='Salesforce_ads'
              description='Bring in your Salesforce objects like Contacts, Accounts etc. and use them inside Factors.'
            />
          }
          BodyComponent={renderSalesforceDataPullBody()}
          applyNoHeaderPadding
        />
      </div>
      <UiDivider />
      <div className='mt-6'>
        <CollapsibleContainer
          openByDefault
          showBorder={false}
          keyName='Salesforce-data-send'
          HeaderComponent={
            <Header
              title='Send data to Salesforce'
              titleIcon='Salesforce_ads'
              description='Configure workflows to add new contacts, accounts in Salesforce directly from Factors.'
            />
          }
          BodyComponent={
            <div className='py-3'>
              <UiButton
                loading={!locked && !isLoaded}
                type={isParagonSalesforceEnabled && 'primary'}
                onClick={() => {
                  if (locked) {
                    handlePlanUpgradeClick();
                    return;
                  }
                  if (isParagonSalesforceEnabled)
                    paragon.uninstallIntegration('salesforce');
                  else paragon.installIntegration('salesforce');
                }}
              >
                {locked
                  ? 'Upgrade plan to enable workflows'
                  : !isLoaded
                    ? 'Loading'
                    : isParagonSalesforceEnabled
                      ? 'Disconnect'
                      : 'Connect'}
              </UiButton>
            </div>
          }
          applyNoHeaderPadding
        />
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  activeProject: state.global.active_project,
  currentProjectSettings: state.global.currentProjectSettings,
  currentAgent: state.agent.agent_details
});

export default connect(mapStateToProps, {
  fetchProjectSettings,
  udpateProjectSettings,
  enableSalesforceIntegration,
  fetchSalesforceRedirectURL
})(SalesForceIntegration);
