import { Modal } from 'antd';
import UiIcon from 'Components/UiIcon';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

const UiModal = (props) => {
  const {
    visible,
    width,
    title,
    onOk,
    onCancel,
    okText,
    cancelText,
    closable,
    confirmLoading,
    className,
    children,
    maskClosable,
    transitionName,
    maskTransitionName,
    icon,
    ...rest
  } = props;

  return (
    <Modal
      centered
      visible={visible}
      width={width}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      className={className}
      okText={okText}
      cancelText={cancelText}
      closable={closable}
      confirmLoading={confirmLoading}
      maskClosable={maskClosable}
      transitionName={transitionName}
      maskTransitionName={maskTransitionName}
      {...rest}
    >
      {icon && <div className='modal-icon'>{icon}</div>}
      {children}
    </Modal>
  );
};

UiModal.propTypes = {
  visible: PropTypes.bool,
  width: PropTypes.number,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  closable: PropTypes.bool,
  confirmLoading: PropTypes.bool,
  className: PropTypes.string,
  maskClosable: PropTypes.bool,
  transitionName: PropTypes.string,
  maskTransitionName: PropTypes.string,
  icon: PropTypes.node
};

UiModal.defaultProps = {
  visible: false,
  width: 700,
  title: null,
  onOk: noop,
  onCancel: noop,
  okText: 'Ok',
  cancelText: 'Cancel',
  closable: false,
  confirmLoading: false,
  className: 'fa-modal--regular',
  icon: null
};

UiModal.info = (config) => {
  Modal.info({
    ...config,
    icon: <UiIcon name='info-circle' />
  });
};

UiModal.success = (config) => {
  Modal.success({
    ...config,
    icon: <UiIcon name='check-circle' />
  });
};

UiModal.error = (config) => {
  Modal.error({
    ...config,
    icon: <UiIcon name='times-circle' />
  });
};

UiModal.warning = (config) => {
  Modal.warning({
    ...config,
    icon: <UiIcon name='exclamation-triangle' />
  });
};

UiModal.confirm = (config) => {
  Modal.confirm({
    ...config,
    icon: <UiIcon name='circle-exclamation' />
  });
};

export default UiModal;
