import UiButton from 'Components/UiButton';
import UiInput from 'Components/UiInput';
import useParagon from 'hooks/useParagon';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
const isSDKWorkflow = true;
export default function Apollo() {
  const activeProjectId = useSelector(
    (state) => state.global.active_project?.id
  );
  const { paragon, user, isLoaded } = useParagon(activeProjectId);

  const isParagonApolloEnabled = useMemo(
    () => user?.integrations?.apolloio?.enabled,
    [activeProjectId, user]
  );

  return (
    <div className='mt-4 flex flex-col w-full'>
      {!isSDKWorkflow && (
        <UiInput style={{ width: 320, marginTop: 8 }} disabled={!isLoaded} />
      )}

      <UiButton
        loading={!isLoaded}
        type={isParagonApolloEnabled && 'primary'}
        style={{ width: 'min-content', margin: '8px 0' }}
        onClick={() => {
          isParagonApolloEnabled
            ? paragon.uninstallIntegration('apolloio')
            : paragon.installIntegration('apolloio');
        }}
      >
        {isParagonApolloEnabled === true ? 'Disconnect' : 'Connect'}
      </UiButton>
    </div>
  );
}
