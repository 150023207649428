// constants
export const CONSTANTS = {
  SAMPLE_CSV_URLS:
    'https://s3.amazonaws.com/www.factors.ai/assets/files/Sample_file_for_page_URL_rules.csv',
  SAMPLE_CSV_FILTERS:
    'https://s3.amazonaws.com/www.factors.ai/assets/files/sample_company_domains.csv',
  FILE_LIMITS: {
    FILTERS_MAX_SIZE: 1000000,
    URLS_MAX_ROWS: 50,
    FILTERS_MAX_ROWS: 10000
  }
};
