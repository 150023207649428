// src/components/UiSkeleton.js
import { Skeleton } from 'antd';
import React from 'react';

const UiSkeleton = ({ avatar, paragraph, title, ...props }) => (
  <Skeleton avatar={avatar} paragraph={paragraph} title={title} {...props} />
);

UiSkeleton.Avatar = Skeleton.Avatar;
UiSkeleton.Input = Skeleton.Input;
UiSkeleton.Image = Skeleton.Image;

export default UiSkeleton;
