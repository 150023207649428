// src/components/UiIcon.js
import { findIconDefinition, library } from '@fortawesome/fontawesome-svg-core';
// import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

// Add FontAwesome icon packs to the library
library.add(fas, far); // fal as needed

// Map custom type to FontAwesome prefix
const prefixMap = {
  regular: 'far',
  solid: 'fas'
  // light: 'fal'
};

// UiIcon component
const UiIcon = ({
  type = 'far', // Default to regular style if no type is provided
  name,
  size = '1x', // Default size is '1x'
  style = {},
  className = '',
  spin = false,
  ...props
}) => {
  const mappedPrefix = prefixMap[type] || type; // Fallback to provided type if not found in map
  const iconDefinition = findIconDefinition({
    prefix: mappedPrefix,
    iconName: name
  });

  if (!iconDefinition) {
    console.error(
      `Icon with type "${mappedPrefix}" and name "${name}" not found.`
    );
    return null;
  }

  let finalClassName = className;

  if (
    spin &&
    !className.includes('anticon-loading') &&
    !className.includes('anticon-spin')
  ) {
    finalClassName += ' anticon-loading anticon-spin';
  }

  return (
    <span className='anticon'>
      {/* Prevents alignment issues inside Antd Components */}
      <FontAwesomeIcon
        icon={iconDefinition}
        size={size}
        style={style}
        className={finalClassName.trim()}
        {...props}
      />
    </span>
  );
};

// Define prop types for better type-checking
UiIcon.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired, // name is required for the icon to be rendered
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  spin: PropTypes.bool
};

// Use React.memo to optimize performance by preventing unnecessary re-renders
export default React.memo(UiIcon);
