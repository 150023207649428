// UiSwitch.js
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const UiSwitch = ({ checked, onChange, disabled, size }) => (
  <Switch
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    size={size}
  />
);

UiSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['default', 'small'])
};

UiSwitch.defaultProps = {
  checked: false,
  onChange: () => {},
  disabled: false,
  size: 'default'
};

export default UiSwitch;
