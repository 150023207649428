const colors = require('Styles/generated-tokens/tailwind/colors');

export const CHART_COLOR_1 = colors.chart[1];
export const CHART_COLOR_2 = colors.chart[2];
export const CHART_COLOR_3 = colors.chart[3];
export const CHART_COLOR_4 = colors.chart[4];
export const CHART_COLOR_5 = colors.chart[5];
export const CHART_COLOR_6 = colors.chart[6];
export const CHART_COLOR_7 = colors.chart[7];
export const CHART_COLOR_8 = colors.chart[8];
export const CHART_COLOR_9 = colors.chart[9];
export const CHART_COLOR_10 = colors.chart[10];
