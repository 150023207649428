import React, { useEffect, useState } from 'react';
import { Button, message, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AdminLock } from 'Routes/feature';
import useAgentInfo from 'hooks/useAgentInfo';
import { fetchProjectSettings } from 'Reducers/global';
import logger from 'Utils/logger';
import { ConfigurableAccountIdentificationProviderDataList } from '../integrations.constants';
import ProviderCard from './ProviderCard';
import { ConfigurableAccountIdentificationProviderData } from '../types';
import { setFactorsDeAnonymisationProvider } from '../../../../../features/onboarding/utils/service';

const ProviderSelection = () => {
  const [currentProvider, setCurrentProvider] = useState('');
  const [localActiveProvider, setLocalActiveProvider] =
    useState<ConfigurableAccountIdentificationProviderData>();
  const { email } = useAgentInfo();
  const { active_project, currentProjectSettings } = useSelector(
    (state) => state?.global
  );
  const dispatch = useDispatch();

  const handleDeanoymisationProviderChange = () => {
    Modal.confirm({
      title: `Are you sure you want to change de-anonymisation provider to ${localActiveProvider?.name}?`,
      content:
        'This will change the de-anonymisation provider for this project',
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await setFactorsDeAnonymisationProvider(
            active_project?.id,
            localActiveProvider?.apiName
          );
          message.success('Successfully changed de-anonymisation provider');
          dispatch(fetchProjectSettings(active_project?.id));
        } catch (error) {
          message.error('Error in changing de-anonymisation provider!');
          logger.error('Error in changing de-anonymisation provider', error);
        }
      },
      onCancel: () => {}
    });
  };
  const handleLocalProviderChange = (
    provider: ConfigurableAccountIdentificationProviderData
  ) => {
    if (provider.apiName === localActiveProvider?.apiName) {
      setLocalActiveProvider(undefined);
    } else setLocalActiveProvider(provider);
  };

  useEffect(() => {
    const factorsDeanonConfig = currentProjectSettings?.factors_deanon_config;

    const activeProvider =
      ConfigurableAccountIdentificationProviderDataList.find(
        (provider) =>
          factorsDeanonConfig?.[provider.identityKey]?.traffic_fraction === 1
      );
    if (activeProvider) {
      setLocalActiveProvider(activeProvider);
      setCurrentProvider(activeProvider.apiName);
    } else {
      const defaultProvider =
        ConfigurableAccountIdentificationProviderDataList[1];
      setLocalActiveProvider(defaultProvider);
      setCurrentProvider(defaultProvider.apiName);
    }
  }, [currentProjectSettings?.factors_deanon_config]);

  if (AdminLock(email))
    return (
      <div>
        <div className='flex gap-4 items-center flex-wrap'>
          {ConfigurableAccountIdentificationProviderDataList.map((provider) => (
            <div
              onClick={() => handleLocalProviderChange(provider)}
              className='cursor-pointer'
            >
              <ProviderCard
                providerName={provider.name}
                isActive={provider.apiName === localActiveProvider?.apiName}
                icon={provider.icon}
                description={provider.description}
              />
            </div>
          ))}
        </div>

        {localActiveProvider &&
          currentProvider !== localActiveProvider?.apiName && (
            <Button
              className='mt-2'
              type='primary'
              onClick={handleDeanoymisationProviderChange}
            >
              Change Provider
            </Button>
          )}
      </div>
    );

  if (localActiveProvider) {
    return (
      <ProviderCard
        providerName={localActiveProvider.name}
        isActive
        icon={localActiveProvider.icon}
      />
    );
  }
  return null;
};

export default ProviderSelection;
